<template>
    <el-dialog tabindex="-1" width="800px" top="40px" title="Granska noter" :visible.sync="visible" :before-close="closeModal" :close-on-click-modal="false">
        <div class="border border-indigo-600 p-4" v-if="data" style="min-height: 1200px;">
            <div class="flex justify-between p-8">
                <div class="flex flex-col">
                    <p>{{ clientInfo.name }}</p>
                    <p>Org.nr {{ clientInfo.registrationNumber }}</p>
                </div>
                <p>x (x)</p>
            </div>
            <div v-if="data.signatures" class="px-8 pb-8 break-normal">
                <div v-if="data.signatures.add">
                    <p class="py-4" v-if="data.signatures.addCompletionTextBeforeSignature">{{ data.signatures.completionTextBeforeSignature }}</p>
                    <h2 class="pt-4 text-base font-bold">Underskrifter</h2>
                    <p class="pb-20">{{ data.signatures.residence }}, {{ signDate }}</p>
                    <div class="signature-layout">
                        <div v-for="(member, index) in data.signatures.boardMembers" :key="index" class="flex flex-col w-80 pb-20">
                            <p>{{ member.firstName }} {{ member.lastName }}</p>
                            <p>{{ member.title }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="data.auditorSignatures" class="px-8 pb-8 break-normal">
                <div v-if="data.auditorSignatures.add" class="space-y-2">
                    <h2 v-if="data.auditorSignatures.addTitle" class="pt-4 text-base font-bold">{{ data.auditorSignatures.headTitle }}</h2>
                    <p v-if="data.auditorSignatures.auditReport.add">{{ auditSignaturReference }}</p>
                    <div v-if="data.auditorSignatures.addCompanyName" class="signature-layout">
                        <div v-for="(audit, index) in data.auditorSignatures.auditPersons" :key="index" class="w-80 py-4">
                            <p>{{ audit.companyName }}</p>
                        </div>
                    </div>
                    <div class="signature-layout">
                        <div v-for="(audit, index) in data.auditorSignatures.auditPersons" :key="index" class="flex flex-col w-80 py-20">
                            <p>{{ audit.firstName }} {{ audit.lastName }}</p>
                            <p>{{ audit.title }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="default">Stäng</el-button>
        </span>
    </el-dialog>
</template>

<script>
import moment from "moment";
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        clientInfo: {
            type: Object,
            default: () => {},
        },
        data: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        //
    },
    methods: {
        closeModal() {
            this.$emit("close");
        },
        formatDate(date, format) {
            if (!date) return " ";
            return format ? moment(date).format("YYYY") : moment(date).format("YYYY-MM-DD");
        },
    },
    computed: {
        signDate() {
            return this.data.signatures.electronicSignatureReferences.add
                ? this.data.signatures.electronicSignatureReferences.text
                : this.formatDate(this.data.signatures.signDate, this.data.signatures.isYearDate);
        },
        auditSignaturReference() {
            const date = this.formatDate(this.data.auditorSignatures.auditReport.signDate, this.data.auditorSignatures.auditReport.isYearDate);

            return this.data.auditorSignatures.auditReport.electronicSignatureReferences.add
                ? this.data.auditorSignatures.auditReport.electronicSignatureReferences.text
                : this.data.auditorSignatures.auditReport.text + date;
        },
    },
};
</script>

<style scoped>
.year-header {
    width: 200px;
    text-align: left;
    word-break: break-word;
    padding: 4px 10px 4px 0px;
}
.year-cell {
    width: 100px;
    text-align: right;
    word-break: break-word;
    padding: 4px 10px 4px 0px;
}
.description-cell {
    width: 200px;
    font-weight: normal;
    word-break: break-word;
    padding: 4px 10px 4px 0px;
}
.amount-cell {
    width: 100px;
    font-weight: normal;
    word-break: break-word;
    text-align: right;
    padding: 4px 10px 4px 0px;
}
.signature-layout {
    display: flex;
    flex-wrap: wrap;
}
</style>
